const certificateData = [
  {
    title: "일생활 균형 캠페인",
    content:
      "게임인스는 일하는 방식과 문화를 개선하여 기업의 생산성을 높이고 근로자의 일과 생활의 균형을 도모하는 '일생활 균형 캠페인'에 참여하는 기업임을 확인합니다. 이 캠페인을 통해 보다 건강하고 효율적인 근로 환경을 만들기 위한 노력을 지속하고 있습니다.",
    image: [require("@/assets/news/news55.png")],
    isImageSmallFrame: true,
    date: "2024.11.27",
    category: "기업인증",
  },
  {
    title: "2024 백스코 잡페스티벌 참가",
    content:
      "(주)게임인스는 저희 게임인스는 2024 부산잡페스티벌에 고용우수기업으로  참가하게 되었습니다. 부산잡페스티벌은 매년 열리는 대규모 취업 박람회로, 지역 내 다양한 기업과 구직자들이 한자리에 모여 서로의 필요를 충족시키는 중요한 행사입니다. 이번 페스티벌은 부산 지역의 경제 활성화와 청년 일자리 창출을 목표로 하며, 많은 기업들이 참여하여 인재를 모집하고, 구직자들은 다양한 직무와 기업에 대한 정보를 얻을 수 있는 기회를 제공합니다. 게임인스는 이번 행사에 참여함으로써, 앞으로도 지속적으로 인재 양성과 고용 창출에 힘쓰며, 지역 사회와 함께 성장하는 기업이 되도록 노력하겠습니다.",
    image: [
      require("@/assets/news/news53-1.png"),
      require("@/assets/news/news53-2.jpg"),
      require("@/assets/news/news53-3.jpg"),
      require("@/assets/news/news53-4.jpg"),
      require("@/assets/news/news53-5.jpg"),
      require("@/assets/news/news53-6.jpg"),
      require("@/assets/news/news53-7.jpg"),
      require("@/assets/news/news53-8.jpg"),
    ],
    isImageSmallFrame: true,
    date: "2024.10.24",
    category: "기업인증",
  },
  {
    title: "중소기업인증",
    content:
      "(주)게임인스는 중소벤처기업부으로부터 중소기업 인증을 획득하여 신뢰할 수 있는 중소기업으로서의 자격을 갖추고 있습니다. 고객 여러분께 더욱 높은 품질의 서비스와 제품을 제공하기 위해 끊임없이 노력하겠습니다.",
    image: [require("@/assets/news/news1.svg")],
    isImageSmallFrame: true,
    date: "2022.08.31",
    category: "기업인증",
  },
  {
    title: "기업부설연구소 인정",
    content:
      "(주)게임인스는 한국산업기술진흥협회로부터 기업부설연구소로 인증받아, 지속적인 연구개발(R&D) 역량을 강화하고 있습니다. 최첨단 기술과 혁신적인 아이디어로 고객 여러분께 최고의 솔루션을 제공하기 위해 최선을 다하겠습니다.",
    image: [require("@/assets/news/news2.svg")],
    isImageSmallFrame: true,
    date: "2022.08.31",
    category: "기업인증",
  },
  {
    title: "2022 서부산권 채용박람회",
    content:
      "<p>(주)게임인스에서는 2022 서부산권채용박람회에 참여하여 지역 내 우수 기업 및 공공기관과의 만남의 장을 통해 청년·중장년·노인·여성 구직자들과 채용을 목적으로 일자리 정보를 구직자에게 제공하여 지역주민의 고용률을 높이기 위해 참석하였습니다.</p><br /><p>박람회참여의 목적)<br />1.지역경제 활성화<br />: 일자리 창출을 통해 지역주민의 소득을 증가시키고 , 지역 경제 발전에 도움<br />2.인재확보<br />: 일자리 박람회를 통해 우수한 인재를 확보, 현장에서 직접 면접을 진행하여 인재를 선발<br />3.기업 홍보<br />: 일자리 박람회를 통해 기업의 비전과 가치, 인재상 등을 소개하고, 구직자들에게 기업의 인지도를 높이며 기업의 이미지 홍보<br />4. 네트워크 구축 : 기업 간 정보를 공유, 협력을 강화할 수 있으며, 새로운 비즈니스 기회를 창출</p>",
    image: [require("@/assets/news/news7.svg")],
    isImageSmallFrame: true,
    date: "2022.10.13",
    category: "기업인증",
  },
  {
    title: "북부고용노동센터 청년 일자리 지원",
    content:
      "(주)게임인스에서는북부고용노동센터 청년 일자리 지원 사업에 참여하여 북부지역내 만남의 장을 통해 청년 채용을 목적으로 일자리 정보를 구직자에게 제공하여 지역주민의 고용률을 높이기 위해 참석하였습니다.",
    image: [
      require("@/assets/news/news45-1.svg"),
      require("@/assets/news/news45-2.svg"),
    ],
    isImageSmallFrame: false,
    date: "2024.05.29",
    category: "기업인증",
  },
  {
    title: "2022 신라대학교 취업박람회",
    content:
      "(주)게임인스에서는 지역 강소 중소기업으로서 2022 신라대학교 취업박람회에 참여하여 신라대학교와의 만남의 장을 통해 구직자들과 채용을 목적으로 일자리 정보를 구직자에게  제공하여 높이기 위해 참석하였습니다.",
    image: [require("@/assets/news/news10.svg")],
    isImageSmallFrame: true,
    date: "2022.11.03",
    category: "기업인증",
  },
  {
    title: "2024 고용우수기업 인증",
    content:
      "(주)게임인스는 일자리 창출과 고용 안정성에서의 우수한 성과를 인정받아 2024고용우수기업으로 인증받았습니다. 이번 인증은 직원들의 헌신과 회사의 지속적인 성장과 혁신의 결과로 얻어진 성과입니다. 앞으로도 (주)게임인스는 모두가 함께 성장할수있는 일터를 만들기 위해 최선을 다하겠습니다. ",
    image: [require("@/assets/news/news47.png")],
    isImageSmallFrame: true,
    date: "2024.06.20",
    category: "기업인증",
  },
  {
    title: "2024 해운대구 일자리 박람회",
    content:
      "(주)게임인스는 해운대구 일자리경제과에서 주최하는 2024해운대구 일자리 박람회에 직접 참여하여 구직자분들과 소중한 만남의 기회를 가졌습니다. 앞으로도 게임인스는 우수한 인재 채용과 지역사회 발전에 기여하기 위해 지속적으로 노력하겠습니다.",
    image: [
      require("@/assets/news/news44-1.svg"),
      require("@/assets/news/news44-2.svg"),
    ],
    isImageSmallFrame: true,
    date: "2024.05.27",
    category: "기업인증",
  },
  {
    title: "벤처기업확인기관장",
    content:
      "(주)게임인스의 우수한 기술력을 인정 받았으며, 첨단 기술과 혁신적인 아이디어를 바탕으로 새로운 시장을 개척하며 지역 경제 활성화에 이바지하는 기업으로 나날이 성장하는 모습을 약속드리겠습니다.",
    image: [require("@/assets/news/news43.svg")],
    isImageSmallFrame: true,
    date: "2024.05.13",
    category: "기업인증",
  },
  {
    title: "대한적십자 헌혈유공자 포장증",
    content:
      "게임인스는 사랑과 봉사의 정신을 발휘하여 인류의 복지 증진 실천으로 대한적십자사로부터 헌혈유공자 포장증 은장을 수상하셨습니다. ",
    image: [require("@/assets/news/news42.svg")],
    isImageSmallFrame: false,
    date: "2024.03.05",
    category: "기업인증",
  },
  {
    title: "가족친화인증",
    content:
      "(주)게임인스는 우수한 가족친화경영 운영체제를 구축하고 가족친화제도를 운영함으로써 근로자의 일과 생활 균형을 지원하는 기업으로 인증서를 수여받았습니다.",
    image: [require("@/assets/news/news38.png")],
    isImageSmallFrame: true,
    date: "2023.12.01",
    category: "기업인증",
  },
  {
    title: "2023 동서대학교 취업 박람회",
    content:
      "(주)게임인스에서는 2023 동서대학교 취업박람회에 참여하여 동서대학교 내 재학생 및 시민의 만남의 장을 통해 채용을 목적으로 일자리 정보를 구직자에게 제공하여 지역주민의 고용률을 높이기 위해 참석하였습니다.",
    image: [
      require("@/assets/news/news36-1.png"),
      require("@/assets/news/news36-2.png"),
    ],
    isImageSmallFrame: false,
    date: "2023.11.08",
    category: "기업인증",
  },
  {
    title: "2023 기장군 일자리 박람회",
    content:
      "(주)게임인스에서는 2023 기장군 일자리 박람회에 간접 참여하여 지역 내 우수 기업 및 공공기관과의 만남의 장을 통해 청년·중장년·노인·여성 구직자들과 채용을 목적으로 일자리 정보를 구직자에게 제공하여 지역주민의 고용률을 높이기 위해 참석하였습니다.",
    image: [require("@/assets/news/news34.png")],
    isImageSmallFrame: true,
    date: "2023.10.26",
    category: "기업인증",
  },
  {
    title: "2023 연제구 일자리 박람회",
    content:
      "(주)게임인스에서는 2023 연제구 일자리 박람회에 직접 참여하여 지역 내 우수 기업 및 공공기관과의 만남의 장을 통해 청년·중장년·노인·여성 구직자들과 채용을 목적으로 일자리 정보를 구직자에게 제공하여 지역주민의 고용률을 높이기 위해 참석하였습니다.",
    image: [require("@/assets/news/news33.jpeg")],
    isImageSmallFrame: true,
    date: "2023.10.23",
    category: "기업인증",
  },
  {
    title: "2023/4 게임인스 하계휴가 안내",
    content:
      "안녕하세요, (주)게임인스입니다.<br /> (주)게임인스를 찾아와주신 분들께 알려드립니다.<br /> 길고 길었던 장마가 끝나고 서서히 더위가 기세를 찾아가는 여름을 맞이하여 ㈜게임인스에서도 다음과 같은 일정으로 하계휴가를 실시합니다.",
    image: [
      require("@/assets/news/news48.png"),
      require("@/assets/news/news28.svg"),
    ],
    isImageSmallFrame: false,
    date: "2024.07.22",
    category: "기업인증",
  },
  {
    title: "게임인스  TNT STUDIO 촬영 스튜디오 사상점 오픈",
    content:
      "2023년 7월에 완공된 TNT Studio 스튜디오는 부산/경남 거점 촬영 스튜디오로, 100평 규모의 영상 제작 공간(호리존, 대형 크로마키존, 개인 스튜디오, 편집실 등)과 장비를 갖추고 있으며, 직접 제작할 뿐만 아니라 지역 크리에이터가 합리적인 비용으로 콘텐츠 제작 장소 문제를 해결할 수 있도록 공간대여 서비스도 운영합니다.<br /><br /> 위와 같은 콘텐츠 제작 지원과 함께, KBS 미디어가 발급하여 공신력 있는 자격증을 HY미디어능력개발원과 협력하여 발급하고 있으며, 크리에이터 창·제작에 도움이 될 교육/육성 시스템을 체계적·전문적으로 운영합니다.<br /><br /> 이외에도 더 좋은 콘텐츠를 개발하고 더 나은 서비스를 제공하기 위하여 끊임없이 역량을 발전시키고, 지역사회와 상생하며 성장해갈 것입니다.<br />",
    image: [
      require("@/assets/news/tntstudio/TNT로고.png"),
      require("@/assets/news/tntstudio/라운지 (로비).png"),
      require("@/assets/news/tntstudio/그린 스튜디오.png"),
      require("@/assets/news/tntstudio/솔로 스튜디오_1.png"),
      require("@/assets/news/tntstudio/파우더 룸(탈의실).png"),
      require("@/assets/news/tntstudio/파우더 룸(화장대).png"),
      require("@/assets/news/tntstudio/하이브리드 스튜디오_1.png"),
      require("@/assets/news/tntstudio/화이트 스튜디오.png"),
    ],
    isImageSmallFrame: false,
    date: "2023.07.21",
    category: "기업인증",
  },
  {
    title: "2022 고용노동부 일 · 생활 균형 캠페인 참여 ",
    content:
      "2022년 고용노동부 주관 '일/생활 균형 캠페인'에 적극 참여하여 일과 생활의 균형을 위한 다양한 활동을 펼쳤습니다. 이 캠페인 참여를 통해 직원들의 삶의 질 향상과 생산성 증대를 도모하고 있으며, 앞으로도 지속 가능한 근무 환경을 조성하기 위해 노력하겠습니다.",
    image: [require("@/assets/news/news26.svg")],
    isImageSmallFrame: true,
    date: "2022.11.14",
    category: "기업인증",
  },
  {
    title: "씀씀이가 바른 기업",
    content:
      "(주)게임인스 ‘씀씀이가 바른기업’ 정기후원 캠페인에 참여하여 나눔과 상생의 가치를 실천하고 있습니다. 이번 캠페인 참여를 통해 어려운 이웃과 지역사회에 따뜻한 손길을 전하고, 사회적 책임을 다하는 기업으로서의 역할을 충실히 해나가고자 합니다. 앞으로도 지속적인 후원과 나눔 활동을 통해 더 나은 사회를 만드는 데 기여하겠습니다.",
    image: [require("@/assets/news/news20.svg")],
    isImageSmallFrame: false,
    date: "2023.04.10",
    category: "기업인증",
  },
  {
    title: "2024 부산장애인진로취업박람회",
    content:
      "주)게임인스에서는 부산광역시와 부산광역시교육청이 주최하고, 부산광역시장애인일자리 통합지원센터,부산광역시 교육청 특수교육지원센터가 주관하는 “2024 부산 장애인 진로,취업박람회”에 참여하여 일하려는 장애인의 최적화된 채용, 장애학생의 적성에 적합한 직업탐색과 진로설계의 기회를 제공하기 위한 금번 행사에 참여하였습니다.",
    image: [require("@/assets/news/news52.jpg")],
    isImageSmallFrame: true,
    date: "2024.09.10",
    category: "기업인증",
  },
];
export default certificateData;
